@font-face {
  font-family: "Gilroy Bold";
  font-display: swap;
  src: local("Gilroy Bold"), url("../fonts/Gilroy-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy SemiBold";
  font-display: swap;
  src: local("Gilroy SemiBold"),
    url("../fonts/Gilroy-Semibold.woff2") format("woff2");
}
