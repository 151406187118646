.icon-hamburger {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  & > * {
    display: block;
    width: 38px;
    height: 5px;
    background: black;
    position: absolute;
    right: 0;
    opacity: 1;
    transition: 0.2s ease-in-out;
    transform-origin: center;
    &.svg-top {
      top: 30%;
    }
    &.svg-bottom {
      top: 70%;
      transform: translateY(-100%);
    }
  }
}

.icon-active {
  .svg-top {
    top: 50%;
    width: 38px;
    transform: translatey(-40%) rotate(-45deg);
  }
  .svg-bottom {
    top: 50%;
    width: 38px;
    transform: translatey(-40%) rotate(45deg);
  }
}
