* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

h1 {
  font-size: 92px;
  font-family: "Gilroy Bold";
  letter-spacing: -0.02em;
}

h2 {
  font-size: 92px;
  font-family: "Gilroy SemiBold";
  letter-spacing: -0.02em;
}

h3 {
  font-size: 64px;
  font-family: "Gilroy Bold";
  letter-spacing: -0.02em;
}

h4 {
  font-size: 42px;
  font-family: "Gilroy Bold";
  letter-spacing: -0.02em;
}

h5 {
  font-size: 42px;
  font-family: "Gilroy SemiBold";
  letter-spacing: -0.02em;
}

h6 {
  font-size: 32px;
  font-family: "Gilroy SemiBold";
  letter-spacing: -0.02em;
}

video {
  background-color: transparent !important;
  clip-path: inset(1px 1px);
  -webkit-clip-path: inset(1px 1px);
}

a {
  color: inherit;
  text-decoration: none;
  font-family: "Gilroy SemiBold";
}

li {
  display: inline;
  list-style: none;
}

.rainbow-text {
  background: linear-gradient(
    105.1deg,
    #3c67ff 8.53%,
    #d864df 52.91%,
    #f88849 77.75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.rainbow-heading {
  width: fit-content;
  background: linear-gradient(
    106.9deg,
    #3c67ff 8.25%,
    #d864df 59.07%,
    #ffa06b 94.33%
  );

  h2 {
    color: black;
    -webkit-text-fill-color: black;
  }

  span {
    -webkit-text-fill-color: transparent;
  }

  background-clip: text;
}

#getInTouch {
  background: linear-gradient(
    98.96deg,
    #557bff 0.93%,
    #d864df 40.8%,
    #ffb084 77%,
    #f88849 95.24%
  );
}

.brandBlock {
  &_arrow {
    width: 41px;
    height: 41px;
    background-repeat: no-repeat;
    background-image: url("../public/images/arrowBlack.svg");
    /* Mobile view "active"*/
    @media (max-width: 640px) {
      background-image: url("../public/images/arrowRainbow.svg");
    }
  }
  &:hover {
    .brandBlock_arrow {
      background-image: url("../public/images/arrowRainbow.svg");
      transition-timing-function: ease-out;
      transition: 0.3s;
    }
  }
}

#welcomeScreenBackground {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f3f3f5;
  width: 100%;

  @media (min-width: 1670px) {
    &:before {
      content: "";
      width: 100%;
      max-width: 1670px;
      height: 1000px;
      display: flex;
      background: linear-gradient(to right, rgba(17, 0, 118, 0) 95%, #f3f3f5),
        linear-gradient(to left, rgba(17, 0, 118, 0) 95%, #f3f3f5);
      position: absolute;
      z-index: 1;
    }
  }
}

.partnerBlock {
  width: 175px;
  height: 250px;
  background: white;
  border-radius: 21px;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: max-content;
    margin: 0 auto;
  }
}
